import React from 'react'
import MapComponent from '../components/mapdetail';
import '../styles/map.css';
import "../styles/App.css";

const Map = () => {
  return (
    <div> 
      <MapComponent/>
    </div>
  )
}

export default Map